<template>
    <ion-page>
        <ion-header>
            <ion-toolbar
                style="--background: #03a9f4; color: white; --min-height: 36px"
                class="custom-toolbar"
            >
                <div class="row align-center">
                    <slot name="before">
                        <div class="shrink">
                            <DxButton
                                type="default"
                                icon="mdi mdi-chevron-left"
                                class="ml-1"
                                @click="$router.go(-1)"
                            />
                        </div>
                    </slot>
                    <slot name="center">
                        <div class="grow text-xs-center">THÔNG TIN TUYẾN</div>
                    </slot>
                    <slot name="after">
                        <div class="shrink" style="opacity: 0">
                            <DxButton
                                type="default"
                                icon="mdi mdi-chevron-left"
                                class="ml-1"
                            /></div
                    ></slot>
                </div>
            </ion-toolbar>
        </ion-header>
        <ion-content :scroll-events="false">
            <ThongTinTuyenVue
                :action="action"
                ref="ThongTinLuongTuyen"
                @LuuThongTin="LuuThongTin"
            />
        </ion-content>
    </ion-page>
</template>
<script>
import {
    IonHeader,
    IonToolbar,
    IonContent,
    IonPage,
    onIonViewWillLeave,
    onIonViewWillEnter,
} from "@ionic/vue";
import { DxButton } from "devextreme-vue";
import ThongTinTuyenVue from "./components/ThongTinTuyen.vue";
export default {
    components: {
        IonHeader,
        IonToolbar,
        IonContent,
        IonPage,
        DxButton,
        ThongTinTuyenVue,
    },
    data() {
        return {
            action: "Add",
            IdLuongTuyen: null,
        };
    },
    computed: {
        ThongTinLuongTuyen: {
            get() {
                return this.$store.state.ThongTinLuongTuyen.ThongTinLuongTuyen;
            },
            set(data) {
                this.$store.commit("ThongTinLuongTuyen/Set", {
                    key: "ThongTinLuongTuyen",
                    data: data,
                });
            },
        },
    },
    methods: {
        getData() {
            let query = this.$route.query;
            if (query.IdLuongTuyen) {
                this.IdLuongTuyen = query.IdLuongTuyen;
                this.action = "Edit";
                this.layChiTietLuongTuyen(query.IdLuongTuyen);
                this.getDanhSachTinhThanh();
                this.getDanhSachLoaiTuyen();
            } else {
                this.getDanhSachTinhThanh();
                this.getDanhSachLoaiTuyen();
            }
        },
        async layChiTietLuongTuyen(idLuongTuyen) {
            try {
                let rs = await this.$store.dispatch(
                    "ThongTinLuongTuyen/Get_ChiTietLuongTuyen",
                    idLuongTuyen,
                );
                if (rs.status == false) {
                    this.$Helper.ThongBaoPopup({
                        message_title: "Lấy chi tiết luồng tuyến thất bại!",
                        message: rs.message,
                    });
                }
            } catch (ex) {
                this.$Helper.ThongBaoPopup({
                    message_title: "Lấy chi tiết luồng tuyến thất bại!",
                    message: "Lỗi hệ thống client.",
                });
            }
        },
        async getDanhSachTinhThanh() {
            try {
                await this.$store.dispatch("ThongTinHeThong/Get_DanhSachTinhThanh");
            } catch (ex) {
                console.log("🚀 ~ file: index.vue:124 ~ getDanhSachTinhThanh ~ ex:", ex);
                this.$Helper.ThongBaoToast(
                    "error",
                    `Lấy danh sách tỉnh thành thất bại! Lỗi hệ thống client.`,
                );
            }
        },
        async getDanhSachLoaiTuyen() {
            try {
                await this.$store.dispatch("ThongTinLuongTuyen/Get_DanhSachLoaiTuyen");
            } catch (ex) {
                this.$Helper.ThongBaoToast(
                    "error",
                    `Lấy danh sách loại tuyến thất bại! Lỗi hệ thống client.`,
                );
            }
        },
        async LuuThongTin() {
            try {
                let self = this;
                let rs;

                if (this.action == "Add") {
                    rs = await this.$store.dispatch("ThongTinLuongTuyen/ThemLuongTuyen");
                } else if (this.action == "Edit") {
                    rs = await this.$store.dispatch(
                        "ThongTinLuongTuyen/SuaLuongTuyen",
                        self.IdLuongTuyen,
                    );
                }
                if (rs.status == true) {
                    this.$Helper.ThongBaoToast(
                        "success",
                        `Lưu thông tin luồng tuyến thành công!`,
                    );
                    if (this.action == "Add") {
                        this.$router.go(-1);
                    }
                    this.getData();
                } else {
                    this.$Helper.ThongBaoPopup({
                        message_title: "Lưu thông tin luồng tuyến thất bại!",
                        message: rs.message,
                    });
                }
            } catch (ex) {
                console.log("🚀 ~ file: index.vue ~ line 137 ~ LuuThemMoi ~ ex", ex);
                this.$Helper.ThongBaoPopup({
                    message_title: "Lưu thông tin luồng tuyến thất bại!",
                    message: "Lỗi hệ thống client.",
                });
            }
        },
    },
    created() {
        onIonViewWillEnter(() => {
            this.getData();
        });
    },
    async mounted() {
        onIonViewWillLeave(() => {
            this.$store.dispatch("ThongTinLuongTuyen/ResetThongTinLuongTuyen");
        });
    },
    watch: {},
};
</script>
