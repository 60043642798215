<template>
    <div class="row justify-center mt-2 px-2" style="margin-bottom: 64px">
        <div class="xs12">
            <DxValidationGroup ref="formValidation">
                <div class="row font-italic font-medium color-warning mt-1">
                    * Lưu ý: Những thông tin có dấu (*) là những thông tin bắt buộc phải
                    điền!
                </div>
                <div class="row mt-2">
                    <div class="xs12">
                        <DxSelectBox
                            v-model="ThongTinLuongTuyen.TinhDi"
                            label="Tỉnh đi (*)"
                            :data-source="DanhSachTinhThanh"
                            display-expr="TenTinh"
                            value-expr="ID_Tinh"
                            labelMode="floating"
                            styling-mode="underlined"
                            validationMessageMode="always"
                            :search-enabled="true"
                            :showClearButton="true"
                            :onItemClick="chonTinhThanh"
                        >
                            <DxValidator>
                                <DxRequiredRule message="Tỉnh đi không được bỏ trống!" />
                            </DxValidator>
                        </DxSelectBox>
                    </div>
                </div>

                <div class="row mt-4">
                    <div class="xs12">
                        <DxSelectBox
                            v-model="ThongTinLuongTuyen.BenDi"
                            label="Bến đi (*)"
                            :data-source="DanhSachBenXeDi"
                            display-expr="TenBenXe"
                            value-expr="IdBenXe"
                            labelMode="floating"
                            styling-mode="underlined"
                            validationMessageMode="always"
                            :search-enabled="true"
                            :showClearButton="true"
                            :onItemClick="TaoMaTuyen"
                        >
                            <DxValidator ref="validateBenDi">
                                <DxRequiredRule message="Bến đi không được bỏ trống!" />
                                <DxCompareRule
                                    :comparisonTarget="
                                        () => {
                                            return ThongTinLuongTuyen.BenDen;
                                        }
                                    "
                                    comparisonType="!="
                                    message="Bến đi không được trùng với bến đến!"
                                />
                            </DxValidator>
                        </DxSelectBox>
                    </div>
                </div>

                <div class="row mt-4">
                    <div class="xs12">
                        <DxSelectBox
                            v-model="ThongTinLuongTuyen.TinhDen"
                            label="Tỉnh đến (*)"
                            :data-source="DanhSachTinhThanh"
                            display-expr="TenTinh"
                            value-expr="ID_Tinh"
                            labelMode="floating"
                            styling-mode="underlined"
                            validationMessageMode="always"
                            :search-enabled="true"
                            :showClearButton="true"
                            :onItemClick="chonTinhThanh"
                        >
                            <DxValidator>
                                <DxRequiredRule message="Tỉnh đến không được bỏ trống!" />
                            </DxValidator>
                        </DxSelectBox>
                    </div>
                </div>

                <div class="row mt-4">
                    <div class="xs12">
                        <DxSelectBox
                            v-model="ThongTinLuongTuyen.BenDen"
                            label="Bến đến (*)"
                            :data-source="DanhSachBenXeDen"
                            display-expr="TenBenXe"
                            value-expr="IdBenXe"
                            labelMode="floating"
                            styling-mode="underlined"
                            validationMessageMode="always"
                            :search-enabled="true"
                            :showClearButton="true"
                            :onItemClick="TaoMaTuyen"
                        >
                            <DxValidator ref="validateBenDen">
                                <DxRequiredRule message="Bến đến không được bỏ trống!" />
                                <DxCompareRule
                                    :comparisonTarget="
                                        () => {
                                            return ThongTinLuongTuyen.BenDi;
                                        }
                                    "
                                    comparisonType="!="
                                    message="Bến đến không được trùng với bến đi!"
                                />
                            </DxValidator>
                        </DxSelectBox>
                    </div>
                </div>

                <div class="row mt-4">
                    <div class="xs12">
                        <DxTextBox
                            v-model="ThongTinLuongTuyen.MaTuyen"
                            label="Mã tuyến (*)"
                            labelMode="floating"
                            styling-mode="underlined"
                            validationMessageMode="always"
                        >
                            <DxValidator>
                                <DxRequiredRule message="Mã tuyến không được bỏ trống!" />
                                <DxStringLengthRule
                                    :max="50"
                                    message="Mã tuyến không được vượt quá 50 ký tự!"
                                />
                            </DxValidator>
                        </DxTextBox>
                    </div>
                </div>

                <div class="row mt-4">
                    <div class="xs12">
                        <DxSelectBox
                            v-model="ThongTinLuongTuyen.LoaiTuyen"
                            label="Loại tuyến (*)"
                            :data-source="DanhSachLoaiTuyen"
                            display-expr="TenLoaiTuyen"
                            value-expr="ID_LoaiTuyen"
                            labelMode="floating"
                            styling-mode="underlined"
                            validationMessageMode="always"
                            :search-enabled="true"
                        >
                            <DxValidator>
                                <DxRequiredRule
                                    message="Loại tuyến không được bỏ trống!"
                                />
                            </DxValidator>
                        </DxSelectBox>
                    </div>
                </div>

                <div class="row title-custom mt-4">
                    Hành trình chạy từ {{ TenBenDi }} đến {{ TenBenDen }}
                </div>

                <div class="row align-center mt-1">
                    <div class="xs12">
                        <DxTextBox
                            v-model="ThongTinLuongTuyen.HanhTrinhChayDi"
                            label="Hành trình chạy (*)"
                            labelMode="floating"
                            styling-mode="underlined"
                            validationMessageMode="always"
                        >
                            <DxValidator ref="refValidateSoDienThoai">
                                <DxRequiredRule
                                    message="Hành trình chạy không được bỏ trống!"
                                />
                                <DxStringLengthRule
                                    :max="512"
                                    message="Hành trình chạy không được vượt quá 512 ký tự!"
                                />
                            </DxValidator>
                        </DxTextBox>
                    </div>

                    <DxButton
                        type="normal"
                        styling-mode="text"
                        icon="mdi mdi-update"
                        @click="
                            CapNhatThongTin(
                                'HanhTrinhChayDi',
                                'Bạn có muốn cập nhật thông tin hành trình chạy từ chiều về không?',
                                `Lưu ý khi cập nhật, yêu cầu thông tin hành trình chạy phải được phân cách nhau bởi dấu '-'. Hệ thống sẽ tự động sắp xếp ngược lại hành trình chạy!`,
                            )
                        "
                    />
                </div>

                <div class="row align-center mt-4">
                    <div class="xs12">
                        <DxNumberBox
                            v-model="ThongTinLuongTuyen.CuLyDi"
                            label="Cự ly (*)"
                            labelMode="floating"
                            validationMessageMode="always"
                            format="#,##0.## km"
                            styling-mode="underlined"
                            valueChangeEvent="keyup"
                            :showSpinButtons="true"
                        >
                            <DxValidator>
                                <DxRequiredRule message="Cự ly không được bỏ trống!" />
                                <DxRangeRule
                                    :min="0"
                                    message="Cự ly không được nhỏ hơn 0!"
                                />
                                <DxRangeRule
                                    :max="2147483647"
                                    message="Cự ly không được vượt quá 2,147,483,647!"
                                />
                            </DxValidator>
                        </DxNumberBox>
                    </div>
                    <DxButton
                        type="normal"
                        styling-mode="text"
                        icon="mdi mdi-update"
                        @click="
                            CapNhatThongTin(
                                'CuLyDi',
                                'Bạn có muốn cập nhật thông tin cự ly từ chiều về không?',
                            )
                        "
                    />
                </div>

                <div class="row mt-4">
                    <div class="xs12">
                        <DxTextBox
                            v-model="ThongTinLuongTuyen.NoiXuatPhatDi"
                            label="Nơi xuất phát"
                            labelMode="floating"
                            styling-mode="underlined"
                            validationMessageMode="always"
                        >
                            <DxTextBoxButton
                                :options="{
                                    icon: 'mdi mdi-information',
                                    type: 'normal',
                                    stylingMode: 'text',
                                    template: 'templatebtn',
                                    activeStateEnabled: false,
                                    focusStateEnabled: false,
                                    hoverStateEnabled: false,
                                }"
                                name="information"
                                location="after"
                            />
                            <template #templatebtn="{ icon }">
                                <div class="row align-center">
                                    <DxButton
                                        type="normal"
                                        styling-mode="text"
                                        icon="mdi mdi-information"
                                        @click="tooltip = true"
                                    />
                                </div>
                            </template>
                            <DxValidator>
                                <DxStringLengthRule
                                    :max="512"
                                    message="Nơi xuất phát không được vượt quá 512 ký tự!"
                                />
                            </DxValidator>
                        </DxTextBox>
                    </div>
                </div>

                <div class="row mt-4">
                    <div class="xs12">
                        <DxTextBox
                            v-model="ThongTinLuongTuyen.NoiKetThucDi"
                            label="Nơi kết thúc"
                            labelMode="floating"
                            styling-mode="underlined"
                            validationMessageMode="always"
                        >
                            <DxTextBoxButton
                                :options="{
                                    icon: 'mdi mdi-information',
                                    type: 'normal',
                                    stylingMode: 'text',
                                    template: 'templatebtn',
                                    activeStateEnabled: false,
                                    focusStateEnabled: false,
                                    hoverStateEnabled: false,
                                }"
                                name="information"
                                location="after"
                            />
                            <template #templatebtn="{ icon }">
                                <div class="row align-center">
                                    <DxButton
                                        type="normal"
                                        styling-mode="text"
                                        icon="mdi mdi-information"
                                        @click="tooltip = true"
                                    />
                                </div>
                            </template>
                            <DxValidator>
                                <DxStringLengthRule
                                    :max="512"
                                    message="Nơi kết thúc không được vượt quá 512 ký tự!"
                                />
                            </DxValidator>
                        </DxTextBox>
                    </div>
                </div>

                <div class="row align-center justify-space-between mb-1 mt-3">
                    <div class="font-16">Danh sách nốt/tài chiều đi</div>

                    <DxButton
                        type="normal"
                        styling-mode="text"
                        icon="mdi mdi-plus-circle"
                        hint="Thêm nốt/tài"
                        class="ml-1"
                        @click="ThemDong('DanhSachGioXuatBenDi')"
                    />
                </div>
                <div class="row">
                    <div class="xs12">
                        <DxDataGrid
                            class="table-page"
                            ref="DanhSachGioXuatBenDi"
                            :show-borders="true"
                            :data-source="ThongTinLuongTuyen.DanhSachGioXuatBenDi"
                            :column-auto-width="true"
                            key-expr=""
                            height="288px"
                            :onEditorPreparing="onEditorPreparing"
                        >
                            <DxEditing
                                :allow-updating="true"
                                :allow-adding="false"
                                :allow-deleting="true"
                                :select-text-on-edit-start="true"
                                :confirmDelete="false"
                                mode="cell"
                            />
                            <DxPaging :enabled="false" />
                            <!-- <DxScrolling mode="virtual" /> -->
                            <DxColumn
                                :allowSorting="true"
                                data-field="GioXuatBen"
                                alignment="left"
                                :caption="`Giờ XB theo biểu đồ giờ (${ThongTinLuongTuyen.DanhSachGioXuatBenDi.length})`"
                                data-type="datetime"
                                format="HH:mm"
                                :editorOptions="{
                                    displayFormat: 'HH:mm',
                                    type: 'time',
                                }"
                                :setCellValue="setCellValueGioXuatBen"
                            >
                                <DxRequiredRule message="Giờ XB không được bỏ trống!" />
                                <DxCustomRule
                                    :validation-callback="customValidationGioXuatBenDi"
                                    message="Giờ XB không được trùng!"
                                />
                            </DxColumn>

                            <DxColumn
                                caption="IdGio"
                                data-field="IdGio"
                                alignment="left"
                                width="0"
                            />

                            <template #customIconDateBox="{}">
                                {{ "\xa0" }}<DxButton icon="mdi mdi-calendar" />
                            </template>
                        </DxDataGrid>
                    </div>
                </div>

                <div class="row align-center justify-space-between mb-1 mt-3">
                    <div class="font-16">Danh sách điểm trả khách theo chiều đi</div>

                    <DxButton
                        type="normal"
                        styling-mode="text"
                        icon="mdi mdi-plus-circle"
                        hint="Thêm nốt/tài"
                        class="ml-1"
                        @click="ThemDong('DanhSachDiemDungDi')"
                    />
                </div>
                <div class="row">
                    <DxDataGrid
                        class="table-page"
                        ref="DanhSachDiemDungDi"
                        :show-borders="true"
                        :data-source="ThongTinLuongTuyen.DanhSachDiemDungDi"
                        height="288px"
                    >
                        <!-- :onRowValidating='onRowValidatingDsDiemDungDi' -->
                        <DxEditing
                            :allow-updating="true"
                            :allow-adding="false"
                            :allow-deleting="true"
                            :select-text-on-edit-start="true"
                            :confirmDelete="false"
                            mode="cell"
                        />
                        <DxPaging :enabled="false" />

                        <!-- <DxScrolling mode="virtual" /> -->
                        <DxColumn
                            :allowSorting="true"
                            data-field="TenDiemDung"
                            :caption="`Điểm trả khách theo chiều đi (${ThongTinLuongTuyen.DanhSachDiemDungDi.length})`"
                            alignment="left"
                            :setCellValue="setCellValueDiemDungNghi"
                        >
                            <DxRequiredRule
                                message="Tên điểm dừng không được bỏ trống!"
                            />
                            <DxStringLengthRule
                                :max="128"
                                message="Tên điểm dừng không được vượt quá 128 ký tự!"
                            />
                        </DxColumn>
                        <DxColumn data-field="IdDiemDung" alignment="left" width="0" />
                    </DxDataGrid>
                </div>

                <div class="row title-custom mt-3">
                    Hành trình chạy từ {{ TenBenDen }} về {{ TenBenDi }}
                </div>

                <div class="row align-center mt-1">
                    <div class="xs12">
                        <DxTextBox
                            v-model="ThongTinLuongTuyen.HanhTrinhChayDen"
                            label="Hành trình chạy (*)"
                            labelMode="floating"
                            styling-mode="underlined"
                            validationMessageMode="always"
                        >
                            <DxValidator>
                                <DxRequiredRule
                                    message="Hành trình chạy không được bỏ trống!"
                                />
                                <DxStringLengthRule
                                    :max="512"
                                    message="Hành trình chạy không được vượt quá 512 ký tự!"
                                />
                            </DxValidator>
                        </DxTextBox>
                    </div>
                    <DxButton
                        type="normal"
                        styling-mode="text"
                        icon="mdi mdi-update"
                        @click="
                            CapNhatThongTin(
                                'HanhTrinhChayDen',
                                'Bạn có muốn cập nhật thông tin hành trình chạy từ chiều đi không?',
                                `Lưu ý khi cập nhật, yêu cầu thông tin hành trình chạy phải được phân cách nhau bởi dấu '-'. Hệ thống sẽ tự động sắp xếp ngược lại hành trình chạy!`,
                            )
                        "
                    />
                </div>

                <div class="row align-center mt-4">
                    <div class="xs12">
                        <DxNumberBox
                            v-model="ThongTinLuongTuyen.CuLyDen"
                            label="Cự ly (*)"
                            labelMode="floating"
                            validationMessageMode="always"
                            format="#,##0.## km"
                            styling-mode="underlined"
                            valueChangeEvent="keyup"
                        >
                            <DxValidator>
                                <DxRequiredRule message="Cự ly không được bỏ trống!" />
                                <DxRangeRule
                                    :min="0"
                                    message="Cự ly không được nhỏ hơn 0!"
                                />
                                <DxRangeRule
                                    :max="2147483647"
                                    message="Cự ly không được vượt quá 2,147,483,647!"
                                />
                            </DxValidator>
                        </DxNumberBox>
                    </div>
                    <DxButton
                        type="normal"
                        styling-mode="text"
                        icon="mdi mdi-update"
                        @click="
                            CapNhatThongTin(
                                'CuLyDen',
                                'Bạn có muốn cập nhật thông tin cự ly từ chiều đi không?',
                            )
                        "
                    />
                </div>

                <div class="row mt-4">
                    <div class="xs12">
                        <DxTextBox
                            v-model="ThongTinLuongTuyen.NoiXuatPhatDen"
                            label="Nơi xuất phát"
                            labelMode="floating"
                            styling-mode="underlined"
                            validationMessageMode="always"
                        >
                            <DxTextBoxButton
                                :options="{
                                    icon: 'mdi mdi-information',
                                    type: 'normal',
                                    stylingMode: 'text',
                                    template: 'templatebtn',
                                    activeStateEnabled: false,
                                    focusStateEnabled: false,
                                    hoverStateEnabled: false,
                                }"
                                name="information"
                                location="after"
                            />
                            <template #templatebtn="{ icon }">
                                <div class="row align-center">
                                    <DxButton
                                        type="normal"
                                        styling-mode="text"
                                        icon="mdi mdi-information"
                                        @click="tooltip = true"
                                    />
                                </div>
                            </template>
                            <DxValidator>
                                <DxStringLengthRule
                                    :max="512"
                                    message="Nơi xuất phát không được vượt quá 512 ký tự!"
                                />
                            </DxValidator>
                        </DxTextBox>
                    </div>
                </div>

                <div class="row mt-4">
                    <div class="xs12">
                        <DxTextBox
                            v-model="ThongTinLuongTuyen.NoiKetThucDen"
                            label="Nơi kết thúc"
                            labelMode="floating"
                            styling-mode="underlined"
                            validationMessageMode="always"
                        >
                            <DxTextBoxButton
                                :options="{
                                    icon: 'mdi mdi-information',
                                    type: 'normal',
                                    stylingMode: 'text',
                                    template: 'templatebtn',
                                    activeStateEnabled: false,
                                    focusStateEnabled: false,
                                    hoverStateEnabled: false,
                                }"
                                name="information"
                                location="after"
                            />
                            <template #templatebtn="{ icon }">
                                <div class="row align-center">
                                    <DxButton
                                        type="normal"
                                        styling-mode="text"
                                        icon="mdi mdi-information"
                                        @click="tooltip = true"
                                    />
                                </div>
                            </template>
                            <DxValidator>
                                <DxStringLengthRule
                                    :max="512"
                                    message="Nơi kết thúc không được vượt quá 512 ký tự!"
                                />
                            </DxValidator>
                        </DxTextBox>
                    </div>
                </div>

                <div class="row align-center justify-space-between mb-1 mt-3">
                    <div class="font-16">Danh sách nốt/tài chiều về</div>

                    <DxButton
                        type="normal"
                        styling-mode="text"
                        icon="mdi mdi-plus-circle"
                        hint="Thêm nốt/tài"
                        class="ml-1"
                        @click="ThemDong('DanhSachGioXuatBenDen')"
                    />
                </div>
                <div class="row">
                    <div class="xs12">
                        <DxDataGrid
                            class="table-page"
                            ref="DanhSachGioXuatBenDen"
                            :show-borders="true"
                            :data-source="ThongTinLuongTuyen.DanhSachGioXuatBenDen"
                            :column-auto-width="true"
                            key-expr=""
                            height="288px"
                            :onEditorPreparing="onEditorPreparing"
                        >
                            <DxEditing
                                :allow-updating="true"
                                :allow-adding="false"
                                :allow-deleting="true"
                                :select-text-on-edit-start="true"
                                :confirmDelete="false"
                                mode="cell"
                            />
                            <DxPaging :enabled="false" />

                            <!-- <DxScrolling mode="virtual" /> -->
                            <DxColumn
                                :allowSorting="true"
                                data-field="GioXuatBen"
                                :caption="`Giờ XB theo biểu đồ giờ (${ThongTinLuongTuyen.DanhSachGioXuatBenDen.length})`"
                                alignment="left"
                                data-type="datetime"
                                format="HH:mm"
                                :editorOptions="{
                                    displayFormat: 'HH:mm',
                                    type: 'time',
                                }"
                                :setCellValue="setCellValueGioXuatBen"
                            >
                                <DxRequiredRule message="Giờ XB không được bỏ trống!" />
                                <DxCustomRule
                                    :validation-callback="customValidationGioXuatBenDen"
                                    message="Giờ XB không được trùng!"
                                />
                            </DxColumn>
                            <DxColumn data-field="IdGio" alignment="left" width="0" />

                            <template #customIconDateBox="{}">
                                {{ "\xa0" }}<DxButton icon="mdi mdi-calendar" />
                            </template>
                        </DxDataGrid>
                    </div>
                </div>

                <div class="row align-center justify-space-between mb-1 mt-3">
                    <div class="font-16">Danh sách điểm trả khách theo chiều về</div>

                    <DxButton
                        type="normal"
                        styling-mode="text"
                        icon="mdi mdi-plus-circle"
                        hint="Thêm nốt/tài"
                        class="ml-1"
                        @click="ThemDong('DanhSachDiemDungDen')"
                    />
                </div>
                <div class="row">
                    <DxDataGrid
                        class="table-page"
                        ref="DanhSachDiemDungDen"
                        :show-borders="true"
                        :data-source="ThongTinLuongTuyen.DanhSachDiemDungDen"
                        height="288px"
                    >
                        <DxEditing
                            :allow-updating="true"
                            :allow-adding="false"
                            :allow-deleting="true"
                            :select-text-on-edit-start="true"
                            :confirmDelete="false"
                            mode="cell"
                        />
                        <DxPaging :enabled="false" />

                        <!-- <DxScrolling mode="virtual" /> -->
                        <DxColumn
                            :allowSorting="true"
                            data-field="TenDiemDung"
                            :caption="`Điểm trả khách theo chiều về (${ThongTinLuongTuyen.DanhSachDiemDungDen.length})`"
                            alignment="left"
                            :setCellValue="setCellValueDiemDungNghi"
                        >
                            <DxRequiredRule
                                message="Tên điểm dừng không được bỏ trống!"
                            />
                            <DxStringLengthRule
                                :max="128"
                                message="Tên điểm dừng không được vượt quá 128 ký tự!"
                            />
                        </DxColumn>
                        <DxColumn
                            caption="IdDiemDung"
                            data-field="IdDiemDung"
                            alignment="left"
                            width="0"
                        />
                    </DxDataGrid>
                </div>

                <div
                    class="row transition mt-4"
                    :style="`max-height: ${
                        openThongTinMoRong ? `1000px` : `32px`
                    }; overflow: hidden`"
                >
                    <div class="xs12">
                        <div
                            class="row align-center font-16 font-medium"
                            @click="openThongTinMoRong = !openThongTinMoRong"
                        >
                            <i
                                class="mdi mdi-menu-down-outline transition"
                                :style="`transform: rotate( ${
                                    openThongTinMoRong ? `180` : `0`
                                }deg);`"
                            ></i>
                            Thông tin mở rộng
                        </div>
                        <div class="row mt-1">
                            <div class="xs12">
                                <DxTagBox
                                    v-model:value="ThongTinLuongTuyen.NhaXe"
                                    label="Nhà xe"
                                    :data-source="DanhSachNhaXeSelect"
                                    display-expr="TenNhaXe"
                                    value-expr="IdNhaXe"
                                    labelMode="floating"
                                    styling-mode="underlined"
                                    validationMessageMode="always"
                                    :search-enabled="true"
                                    :showDropDownButton="true"
                                >
                                </DxTagBox>
                            </div>
                        </div>
                    </div>
                </div>
            </DxValidationGroup>
        </div>
    </div>

    <div class="button-bottom row justify-space-between align-center">
        <DxButton text="Hủy" type="danger" styling-mode="text" @click="$router.go(-1)" />
        <DxButton
            text="Lưu"
            type="default"
            styling-mode="contained"
            v-if="
                action == 'Add'
                    ? $Helper.KiemTraQuyen(
                          $t('QuyenLenhDienTu.LENHDIENTU'),
                          $t('QuyenLenhDienTu.ThemLuongTuyen'),
                      )
                    : $Helper.KiemTraQuyen(
                          $t('QuyenLenhDienTu.LENHDIENTU'),
                          $t('QuyenLenhDienTu.SuaLuongTuyen'),
                      )
            "
            :disabled="isEditing"
            @click="LuuThongTin()"
        />
    </div>
    <div v-show="tooltip" class="tooltip" @click="tooltip = false">
        <div class="row align-center">
            <i class="mdi mdi-information font-24 mr-2"></i>Thông tin này sẽ được hiển thị
            trên bản thể hiện Lệnh điện tử!
        </div>
    </div>

    <!-- Xác nhận cập nhật thông tin -->
    <PopupVue
        height="auto"
        v-if="ifPopupXacNhanCapNhatThongTin"
        v-model:dialog="PopupXacNhanCapNhatThongTin"
        title=""
        buttonTextLeft="Hủy"
        buttonTextRight="Đồng ý"
        class="popup-padding-8"
        @close="XacNhanCapNhatThongTin"
        heightScrollView="auto"
    >
        <template #content>
            <ThongBaoVue
                :message="ParamThongBao.message"
                :state="ParamThongBao.state"
                :title="ParamThongBao.title"
            />
        </template>
    </PopupVue>
</template>

<script>
import { mapState } from "vuex";
import {
    DxTextBox,
    DxSelectBox,
    DxDateBox,
    DxButton,
    DxValidator,
    DxValidationGroup,
    DxNumberBox,
    DxTagBox,
    DxCheckBox,
} from "devextreme-vue";
import { DxButton as DxTextBoxButton } from "devextreme-vue/text-box";
import {
    DxNumericRule,
    DxPatternRule,
    DxRequiredRule,
    DxStringLengthRule,
    DxRangeRule,
    DxDataGrid,
    DxPaging,
    DxScrolling,
    DxColumn,
    DxEditing,
    DxCustomRule,
    DxCompareRule,
} from "devextreme-vue/data-grid";
import PopupVue from "../../../../../components/_Common/Popup.vue";
import ThongBaoVue from "../../../../../components/_Common/ThongBao.vue";
let timeOutCloseTooltip = false;
export default {
    components: {
        DxTextBox,
        DxSelectBox,
        DxRequiredRule,
        DxValidator,
        DxStringLengthRule,
        DxNumericRule,
        DxPatternRule,
        DxValidationGroup,
        DxDateBox,
        DxButton,
        DxNumberBox,
        DxRangeRule,
        DxTagBox,
        DxDataGrid,
        DxPaging,
        DxScrolling,
        DxColumn,
        DxEditing,
        DxCustomRule,
        DxCompareRule,
        DxCheckBox,
        DxTextBoxButton,
        PopupVue,
        ThongBaoVue,
    },
    props: {
        action: { type: String, default: "Add" },
    },
    data() {
        return {
            resetQuanHuyen: false,
            KTDiemTraKhach: false,
            tooltip: false,
            openThongTinMoRong: false,
            ifPopupXacNhanCapNhatThongTin: false,
            PopupXacNhanCapNhatThongTin: false,
            KeyCapNhatThongTin: null,
            ParamThongBao: {
                state: "Warning",
                title: "Xác nhận chuyển đổi lệnh",
                message: "",
            },
            isEditing: false,
        };
    },
    computed: {
        ...mapState({
            DanhSachTinhThanh: (state) => state.ThongTinHeThong.DanhSachTinhThanh,
            DanhSachBenXeDi: (state) => state.ThongTinBenXe.DanhSachBenXeDi,
            DanhSachBenXeDen: (state) => state.ThongTinBenXe.DanhSachBenXeDen,
            DanhSachLoaiTuyen: (state) => state.ThongTinLuongTuyen.DanhSachLoaiTuyen,
            ThongTinLuongTuyen: (state) => state.ThongTinLuongTuyen.ThongTinLuongTuyen,
            DanhSachNhaXeSelect: (state) => state.ThongTinLaiXe.DanhSachNhaXeSelect,
        }),
        TenBenDi() {
            if (this.DanhSachBenXeDi.length > 0 && this.ThongTinLuongTuyen.BenDi) {
                let arrItem = this.DanhSachBenXeDi.filter(
                    (e) => e.IdBenXe == this.ThongTinLuongTuyen.BenDi,
                );
                return arrItem.length > 0 ? `"${arrItem[0].TenBenXe}"` : `"Bến đi"`;
            }
            return `"Bến đi"`;
        },
        TenBenDen() {
            if (this.DanhSachBenXeDen.length > 0 && this.ThongTinLuongTuyen.BenDen) {
                let arrItem =
                    this.DanhSachBenXeDen.filter(
                        (e) => e.IdBenXe == this.ThongTinLuongTuyen.BenDen,
                    ) || [];
                return arrItem.length > 0 ? `"${arrItem[0].TenBenXe}"` : `"Bến đến"`;
            }
            return `"Bến đến"`;
        },
    },
    watch: {
        "ThongTinLuongTuyen.TinhDi": {
            handler: function () {
                this.getBenXe("DanhSachBenXeDi", "TinhDi", "BenDi");
            },
        },
        "ThongTinLuongTuyen.TinhDen": {
            handler: function () {
                this.getBenXe("DanhSachBenXeDen", "TinhDen", "BenDen");
            },
        },
        tooltip() {
            clearTimeout(timeOutCloseTooltip);
            if (this.tooltip) {
                timeOutCloseTooltip = setTimeout(() => {
                    this.tooltip = false;
                }, 5000);
            }
        },
        PopupXacNhanCapNhatThongTin() {
            if (!this.PopupXacNhanCapNhatThongTin) {
                this.NgatDOMPopup("ifPopupXacNhanCapNhatThongTin");
            }
        },
    },
    methods: {
        showPopup(name) {
            this["if" + name] = true;
            this[name] = true;
        },
        NgatDOMPopup(key) {
            setTimeout(() => {
                this[key] = false;
            }, 300);
        },
        onEditorPreparing(e) {
            if (e.parentType == "dataRow") {
                if (
                    e.editorOptions.type == "datetime" ||
                    e.editorOptions.type == "date" ||
                    e.editorOptions.type == "time"
                ) {
                    e.editorOptions.useMaskBehavior = true;
                    e.editorOptions.dropDownButtonTemplate = "customIconDateBox";
                }
            }
        },
        async LayDanhSachNhaXe() {
            try {
                let rs = await this.$store.dispatch("ThongTinLaiXe/LayDanhSachNhaXe");

                if (!rs.status) {
                    this.ThongBao(
                        "error",
                        `Lấy danh sách nhà xe thất bại! ${rs.message}`,
                    );
                } else {
                }
            } catch (error) {
                console.log(error);
            }
        },
        onRowValidatingDsDiemDungDi(e) {
            this.KTDiemTraKhach = e.isValid;
        },
        chonTinhThanh() {
            this.resetQuanHuyen = true;
        },
        getBenXe(keyDanhSach, keyTinh, keyBen) {
            if (this.resetQuanHuyen) {
                this.ThongTinLuongTuyen[keyBen] = null;
                this.resetQuanHuyen = false;
            }
            if (this.ThongTinLuongTuyen[keyTinh]) {
                this.getDanhSachBenXe(keyDanhSach, this.ThongTinLuongTuyen[keyTinh]);
            } else {
                this.$store.commit("ThongTinBenXe/Set", {
                    key: keyDanhSach,
                    data: [],
                });
            }
        },
        async getDanhSachBenXe(key, value) {
            try {
                let rs = await this.$store.dispatch(
                    "ThongTinBenXe/Get_DanhSachBenXeTheoTinh",
                    { key, value },
                );
                if (rs.length == 0) {
                    this.$Helper.ThongBaoToast(
                        "error",
                        `Không có bến xe thuộc tỉnh này!`,
                    );
                }
            } catch (ex) {
                console.log(
                    "🚀 ~ file: ThongTinTuyen.vue:926 ~ getDanhSachBenXe ~ ex:",
                    ex,
                );
                this.$Helper.ThongBaoToast(
                    "error",
                    `Lấy danh sách bến xe thất bại! Lỗi hệ thống client.`,
                );
            }
        },
        customValidationGioXuatBenDi(e) {
            if (this.ThongTinLuongTuyen.DanhSachGioXuatBenDi.length > 0) {
                return !this.ThongTinLuongTuyen.DanhSachGioXuatBenDi.some(
                    (dv) =>
                        new Date(dv.GioXuatBen).getHours() ==
                            new Date(e.value).getHours() &&
                        new Date(dv.GioXuatBen).getMinutes() ==
                            new Date(e.value).getMinutes(),
                );
            }
            return true;
        },
        customValidationGioXuatBenDen(e) {
            if (this.ThongTinLuongTuyen.DanhSachGioXuatBenDen.length > 0) {
                return !this.ThongTinLuongTuyen.DanhSachGioXuatBenDen.some(
                    (dv) =>
                        new Date(dv.GioXuatBen).getHours() ==
                            new Date(e.value).getHours() &&
                        new Date(dv.GioXuatBen).getMinutes() ==
                            new Date(e.value).getMinutes(),
                );
            }
            return true;
        },
        setCellValueGioXuatBen(newData, value, currentRowData) {
            newData.GioXuatBen = value;
            newData.IdGio = currentRowData.IdGio ? currentRowData.IdGio : null;
        },
        setCellValueDiemDungNghi(newData, value, currentRowData) {
            newData.TenDiemDung = value;
            newData.IdDiemDung = currentRowData.IdDiemDung
                ? currentRowData.IdDiemDung
                : null;
        },
        async TaoMaTuyen() {
            let MaTuyen = null;
            if (this.ThongTinLuongTuyen.BenDi && this.ThongTinLuongTuyen.BenDen) {
                let tmpTinhDi = this.DanhSachTinhThanh.filter(
                    (e) => e.ID_Tinh == this.ThongTinLuongTuyen.TinhDi,
                )[0];
                let tmpTinhDen = this.DanhSachTinhThanh.filter(
                    (e) => e.ID_Tinh == this.ThongTinLuongTuyen.TinhDen,
                )[0];
                let tmpBenDi = this.DanhSachBenXeDi.filter(
                    (e) => e.IdBenXe == this.ThongTinLuongTuyen.BenDi,
                )[0];
                let tmpBenDen = this.DanhSachBenXeDen.filter(
                    (e) => e.IdBenXe == this.ThongTinLuongTuyen.BenDen,
                )[0];
                if (tmpTinhDi.MaTinh > tmpTinhDen.MaTinh) {
                    MaTuyen =
                        tmpTinhDen.MaTinh +
                        tmpTinhDi.MaTinh +
                        "." +
                        tmpBenDen.MaBenXe +
                        tmpBenDi.MaBenXe;
                } else if (tmpTinhDen.MaTinh > tmpTinhDi.MaTinh) {
                    MaTuyen =
                        tmpTinhDi.MaTinh +
                        tmpTinhDen.MaTinh +
                        "." +
                        tmpBenDi.MaBenXe +
                        tmpBenDen.MaBenXe;
                } else if (tmpTinhDen.MaTinh == tmpTinhDi.MaTinh) {
                    let MaBen =
                        tmpBenDi.MaBenXe > tmpBenDen.MaBenXe
                            ? tmpBenDen.MaBenXe + tmpBenDi.MaBenXe
                            : tmpBenDi.MaBenXe + tmpBenDen.MaBenXe;
                    MaTuyen = tmpTinhDi.MaTinh + tmpTinhDen.MaTinh + "." + MaBen;
                }
                try {
                    this.$startLoading;
                    let rs = await this.$store.dispatch(
                        "ThongTinLuongTuyen/GetMaTuyenGoiY",
                        MaTuyen,
                    );
                    if (rs.status) {
                        this.ThongTinLuongTuyen.MaTuyen = rs.data;
                    }
                    this.$stopLoading;
                } catch (error) {
                    console.log("🚀 ~ error", error);
                    this.$stopLoading;
                }
            }
        },
        ThemDong(refName) {
            if (this.$refs[refName]) {
                this.$refs[refName].instance.addRow();
            }
        },
        async LuuThongTin() {
            let validate = this.$refs.formValidation.instance.validate();

            if (validate.isValid) {
                this.$emit("LuuThongTin");
            } else {
                return this.$Helper.autoFocusError(validate);
            }
        },
        CapNhatThongTin(key, title, message) {
            this.KeyCapNhatThongTin = key;
            this.ParamThongBao.message = message;
            this.ParamThongBao.title = title;
            this.showPopup("PopupXacNhanCapNhatThongTin");
        },
        XacNhanCapNhatThongTin(bool) {
            if (bool == false) {
                this.PopupXacNhanCapNhatThongTin = false;
            } else if (bool == true) {
                this.PopupXacNhanCapNhatThongTin = false;
                if (this.KeyCapNhatThongTin == "HanhTrinhChayDen") {
                    if (!this.ThongTinLuongTuyen.HanhTrinhChayDi) {
                        this.$Helper.ThongBaoToast(
                            "error",
                            `Hành trình chạy chiều đi đang không có dữ liệu!`,
                        );
                    } else {
                        let arrText = this.ThongTinLuongTuyen.HanhTrinhChayDi.split("-");
                        arrText.reverse();
                        this.ThongTinLuongTuyen.HanhTrinhChayDen = arrText.join(" - ");
                    }
                }
                if (this.KeyCapNhatThongTin == "HanhTrinhChayDi") {
                    if (!this.ThongTinLuongTuyen.HanhTrinhChayDen) {
                        this.$Helper.ThongBaoToast(
                            "error",
                            `Hành trình chạy chiều về đang không có dữ liệu!`,
                        );
                    } else {
                        let arrText = this.ThongTinLuongTuyen.HanhTrinhChayDen.split("-");
                        arrText.reverse();
                        this.ThongTinLuongTuyen.HanhTrinhChayDi = arrText.join(" - ");
                    }
                }
                if (this.KeyCapNhatThongTin == "CuLyDi") {
                    if (!this.ThongTinLuongTuyen.CuLyDen) {
                        this.$Helper.ThongBaoToast(
                            "error",
                            `Cự ly chiều về đang không có dữ liệu!`,
                        );
                    } else {
                        this.ThongTinLuongTuyen.CuLyDi = this.ThongTinLuongTuyen.CuLyDen;
                    }
                }
                if (this.KeyCapNhatThongTin == "CuLyDen") {
                    if (!this.ThongTinLuongTuyen.CuLyDi) {
                        this.$Helper.ThongBaoToast(
                            "error",
                            `Cự ly chiều đi đang không có dữ liệu!`,
                        );
                    } else {
                        this.ThongTinLuongTuyen.CuLyDen = this.ThongTinLuongTuyen.CuLyDi;
                    }
                }
            }
        },
    },
    created() {
        this.LayDanhSachNhaXe();
        var self = this;

        this.intervalCheckDxEditing = setInterval(() => {
            if (
                !self.$refs.DanhSachDiemDungDen ||
                !self.$refs.DanhSachDiemDungDen.instance ||
                !self.$refs.DanhSachDiemDungDi ||
                !self.$refs.DanhSachDiemDungDi.instance ||
                !self.$refs.DanhSachGioXuatBenDi ||
                !self.$refs.DanhSachGioXuatBenDi.instance ||
                !self.$refs.DanhSachGioXuatBenDen ||
                !self.$refs.DanhSachGioXuatBenDen.instance
            )
                return;

            var isEditingDanhSachDiemDungDen = self.$refs.DanhSachDiemDungDen.instance
                .getController("data")
                .items()
                .some((x) => x.isEditing);
            var isEditingDanhSachDiemDungDi = self.$refs.DanhSachDiemDungDi.instance
                .getController("data")
                .items()
                .some((x) => x.isEditing);
            var isEditingDanhSachGioXuatBenDi = self.$refs.DanhSachGioXuatBenDi.instance
                .getController("data")
                .items()
                .some((x) => x.isEditing);
            var isEditingDanhSachGioXuatBenDen = self.$refs.DanhSachGioXuatBenDen.instance
                .getController("data")
                .items()
                .some((x) => x.isEditing);
            this.isEditing =
                isEditingDanhSachDiemDungDen ||
                isEditingDanhSachDiemDungDi ||
                isEditingDanhSachGioXuatBenDi ||
                isEditingDanhSachGioXuatBenDen;
        }, 300);
    },
};
</script>

<style scoped>
.frame-box {
    width: 100%;
    padding: 16px 24px;
}
.title-custom {
    font-weight: 600;
    font-size: 16px;
}
.tooltip {
    position: fixed;
    z-index: 3;
    background: transparent;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    -webkit-transition: all 0.3s ease-in-out !important;
    -moz-transition: all 0.3s ease-in-out !important;
    -o-transition: all 0.3s ease-in-out !important;
    -ms-transition: all 0.3s ease-in-out !important;
    transition: all 0.3s ease-in-out !important;
}
.tooltip > div {
    position: fixed;
    bottom: 28px;
    z-index: 4;
    box-shadow: 0 6px 12px #dadce0;
    background: #fb8c00;
    color: #fff;
    padding: 12px 16px;
    border-radius: 4px;
    font-size: 16px;
    font-weight: 500;
    left: calc(50vw - calc(50vw - 24px));
    width: calc(100vw - 48px);
    line-height: 24px;
    -webkit-transition: all 0.3s ease-in-out !important;
    -moz-transition: all 0.3s ease-in-out !important;
    -o-transition: all 0.3s ease-in-out !important;
    -ms-transition: all 0.3s ease-in-out !important;
    transition: all 0.3s ease-in-out !important;
}
:deep(.dx-tagbox.dx-texteditor.dx-editor-underlined::after) {
    border-bottom: 1px solid rgba(0, 0, 0, 0.42) !important;
}
:deep(.dx-tagbox.dx-texteditor.dx-state-active::before),
:deep(.dx-tagbox.dx-texteditor.dx-state-focused::before) {
    border-bottom: 2px solid #03a9f4 !important;
    transform: scale(1) !important;
    transition: transform 0.6s cubic-bezier(0.4, 0, 0.02, 1) !important;
}
</style>
